import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

export default class extends ApplicationController {
  static get targets() {
    return ['close'];
  }

  initialize() {
    this.props = {
      modalController: null,
      initialPersistent: null,
      initialCloseOnBgClick: null,
    };
  }

  connect() {
    defer(this.setInitialProps.bind(this));
  }

  disconnect() {
    if (isVariableDefinedNotNull(this.props.initialPersistent)) {
      this.persistent = this.props.initialPersistent;
      this.props.initialPersistent = null;
    }

    if (isVariableDefinedNotNull(this.props.initialCloseOnBgClick)) {
      this.closeOnBgClick = this.props.initialCloseOnBgClick;
      this.props.initialCloseOnBgClick = null;
    }
  }

  setInitialProps() {
    const contentPersistent = this.element.dataset.persistent === 'true';
    const contentCloseOnBgClick = this.element.dataset.closeOnBgClick === 'true';

    if (contentPersistent !== this.persistent) {
      this.props.initialPersistent = this.persistent;
      this.persistent = contentPersistent;
    }

    if (contentCloseOnBgClick !== this.closeOnBgClick) {
      this.props.initialCloseOnBgClick = this.closeOnBgClick;
      this.closeOnBgClick = contentCloseOnBgClick;
    }
  }

  preventClose() {
    this.persistent = true;
  }

  allowClose() {
    this.persistent = false;
  }

  acceptModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.accept(closeModal, { data });
  }

  rejectModal(closeModal = true, { data = undefined } = {}) {
    this.modalController.reject(closeModal, { data });
  }

  acceptAndCloseFromEvent(event) {
    if (!event || !isVariableDefinedNotNull(event.detail.success) || event.detail.success) {
      this.acceptModal(true);
    }
  }

  acceptOnDone(event) {
    const {
      detail: { target = null },
    } = event;

    if (!target || !this.acceptWhenDone || !this.element.contains(target)) {
      return;
    }

    this.acceptModal(true);
  }

  set persistent(value) {
    this.modalController.persistent = value;
  }

  get persistent() {
    return this.modalController.persistent;
  }

  set closeOnBgClick(value) {
    this.modalController.closeOnBgClick = value;
  }

  get closeOnBgClick() {
    return this.modalController.closeOnBgClick;
  }

  get acceptWhenDone() {
    return this.element.dataset.acceptWhenDone === 'true';
  }

  get modalController() {
    if (!this.props.modalController) {
      this.props.modalController = this.findParentControllerByName('fuse--modal-v2', {
        hideWarning: true,
      });
    }

    return this.props.modalController;
  }
}
